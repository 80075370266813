import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;

// 查询物料库存列表(批次)
export function selectPage(query) {
  return request({
    url: "/inventory/materialStock/selectPage",
    method: "get",
    params: query
  });
}
// 查询物料库存列表(总)
export function selectTotalPage(query) {
  return request({
    url: "/inventory/materialStock/selectTotalPage",
    method: "get",
    params: query
  });
}

// 修改预警状态
export function changeWarningStatus(data) {
  return request({
    url: "/inventory/materialStock/warning",
    method: "put",
    params: data
  });
}

// 修改预警状态
export function warningLimit(data) {
  return request({
    url: "/inventory/materialStock/warningLimit",
    method: "put",
    params: data
  });
}

export const findMaterialByWhId = params => {
  return request({
    url: "/inventory/materialStock/findMaterialByWhId/",
    method: "get",
    params
  });
};

// 设置库存快照
export function addSnapshoot(whId) {
  return request({
    url: "/inventory/materialStock/snapshoot/"+whId,
    method: "post"
  });
}

// 查询库存快照
export function findSnapshoot(params) {
  return request({
    url: "/inventory/materialStock/snapshoot",
    method: "get",
    params
  });
}

// 查询库存快照状态
export function isSnapshoot() {
  return request({
    url: "/inventory/inConfig/isSnapshoot/",
    method: "get"
  });
}

// 开启/关闭 库存快照
export function editSnapshoot(status) {
  return request({
    url: "/inventory/inConfig/editSnapshoot/"+status,
    method: "put"
  });
}

// 申请调拨
export function applyTransfer(materialStockId) {
  return request({
    url: "/inventory/materialStock/applyTransfer/"+materialStockId,
    method: "put"
  });
}

// 审批调拨
export function approveTrans(data) {
  return request({
    url: "/inventory/materialStock/approveTrans",
    method: "put",
    data
  });
}
